import React from "react";
import {
  SEO,
  PageLayout,
  ContactForm,
  MenuFilesDisplay,
} from "@bluefin/components";
import { Grid, Header, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={false}
          subfooter={false}
          className={"catering-page custom-page"}
        >
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"center"} width={14}>
              <Header as={"h1"}>Catering</Header>
              <h3>
                Thank you for your interest in having Bennie's Pizza Pub cater
                your next big event!
              </h3>
              <Image
                size={"medium"}
                centered={true}
                src={
                  "https://fisherman.gumlet.io/public/2e1376fa-0d04-49e4-bea2-1d9351a37203/Catering.jpg"
                }
              />
              <p>
                We offer in house and on site catering delivery to locations
                within a 10 mile radius. On-site delivery must be a minimum of
                $100, and ask how you can get your delivery for free. We do not
                cater events on Sundays or federal holidays when we are closed.
                Once you have completed the catering request form someone will
                be in contact with you to finalize details.
              </p>
            </Grid.Column>
            <Grid.Column textAlign={"left"} width={"8"}>
              <ContactForm
                constructMessage={({
                  name,
                  email,
                  phone,
                  date,
                  where,
                  guest,
                  time,
                }) =>
                  `Someone has reached out to you via your website catering form: Name: ${name}, Email: ${email}, Phone: ${phone}, Date of Event: ${date}, On location or in-house?: ${where}, Number of People: ${guest}, Approximate Preferred Delivery Time: ${time}`
                }
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "date",
                    displayLabel: "Date of Event",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "where",
                    displayLabel: "On location or in-house?",
                    placeholder: "On location or in-house?",
                    type: "select",
                    required: true,
                    options: [
                      { label: "Location", value: "Location" },
                      { label: "In-House?", value: "In-House?" },
                    ],
                  },
                  {
                    label: "guest",
                    displayLabel: "Number of People",
                    placeholder: "Number of People",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "time",
                    displayLabel: "Food Service Time",
                    placeholder: "Food Service Time",
                    type: "input",
                    required: true,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                destinationEmail={"partybookings@benniespizzapub.net"}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"center"} width={14}>
              <MenuFilesDisplay
                files={[
                  {
                    url: "https://fisherman.gumlet.io/public/benniespizzapub/Bennies-Let2-Catering-Menu-ASB5416V.pdf",
                    filename: "Menu",
                  },
                ]}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Catering" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
